import { getOS, getBrowser, getUserSegmentIdsLocal, getHBTimeoutObject, getGoogletag } from "../common/functions/siteInfo";
import { initBidManager, initApstag, fetchHeaderBids } from "../common/functions/headerBidManager";
import * as thirdPartyManager from "../common/functions/thirdPartyManager";
import { makeGeoEdgeTag } from "../common/functions/geoEdge";

// GeoEdgeタグ挿入
makeGeoEdgeTag();

//セット値がない時
if (typeof window.vm_sippo_tag_info === "undefined") {
  if (location.pathname == "/") {
    window.vm_sippo_tag_info = "top";
  } else {
    window.vm_sippo_tag_info = "0";
  }
}

//タイムアウト値のABテスト用オブジェクト
var timeout_abtest_obj = getHBTimeoutObject();
var timeOutObj = {
  failSafe: timeout_abtest_obj["failSafeTimeout"],
  apstagBid: 2000,
};

var headerBiddingSlots = [];
// prettier-ignore
var apstagSlots = [
  {
    slotID: "head",
    slotName: "/57465213/vm/sippo/SP/head",
    sizes: [[320, 50],[320, 150],[320, 180],],
  },
  {
    slotID: "floating_bnr",
    slotName: "/57465213/vm/sippo/SP/floating_bnr",
    sizes: [[320, 50]],
  },
];

// Prebid.js load library
var os = getOS();
var browser = getBrowser();
var dm_query_params = "?os=" + os + "&browser=" + browser;

var segments = getUserSegmentIdsLocal();

// get googletag
var googletag = getGoogletag();

thirdPartyManager.init(googletag);

initBidManager(googletag, dm_query_params, timeOutObj);
initApstag();

googletag.cmd.push(function () {
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/webpush", [1, 1], "div-gpt-ad-1583803416973-0").addService(googletag.pubads()));
  // prettier-ignore
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/head",[[1, 1],[320, 50],[320, 100],[320, 150],[320, 180],],"head").addService(googletag.pubads()));
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/new", "fluid", "div-gpt-ad-1518077142244-7").addService(googletag.pubads()));
  // prettier-ignore
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/top_rect01",[[300, 250],[336, 280],],"div-gpt-ad-1518077142244-16").addService(googletag.pubads()));
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/pick01", "fluid", "div-gpt-ad-1518077142244-9").addService(googletag.pubads()));
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/pick02", "fluid", "div-gpt-ad-1518077142244-10").addService(googletag.pubads()));
  // prettier-ignore
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/top_rect02",[[300, 250],[336, 280],],"div-gpt-ad-1518077142244-17").addService(googletag.pubads()));
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/info01", "fluid", "div-gpt-ad-1518077142244-2").addService(googletag.pubads()));
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/foot", [300, 250], "div-gpt-ad-1518077142244-0").addService(googletag.pubads()));
  headerBiddingSlots.push(googletag.defineSlot("/57465213/vm/sippo/SP/floating_bnr", ["fluid", [1, 1], [320, 50]], "floating_bnr").addService(googletag.pubads()));
  window.vm_sippo_tag_info ? googletag.pubads().setTargeting("vm_sippo_tag_info", window.vm_sippo_tag_info) : null;
  segments ? googletag.pubads().setTargeting("CxSegments", segments) : null;
  googletag.pubads().setTargeting("vm_url_host", location.hostname);
  googletag.pubads().disableInitialLoad();
  googletag.pubads().enableSingleRequest();
  googletag.pubads().collapseEmptyDivs();
  googletag.enableServices();
});

googletag.cmd.push(function () {
  fetchHeaderBids(apstagSlots, headerBiddingSlots, thirdPartyManager.setServices);
});
